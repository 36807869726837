import React, { useState } from 'react';
import { BG, Logo } from '../assets';
import { useNavigate } from 'react-router-dom';
import { usePackageContext } from '../hooks/PackageContext';
import axios from 'axios';

const SelectedPlanActiveStyle = 'border-2 border-solid border-blue-600';

export default function Home() {
  const [selectedBundle, setSelectedBundle] = useState(null);
  const navigate = useNavigate();
  const { setSelectedBundleContext, bundles } = usePackageContext();

  const handleBundleClick = (bundle) => {
    setSelectedBundle(bundle);
  };

  const requestPayment = async () => {
    try {
      // Check if a bundle is selected
      if (!selectedBundle) {
        console.error('No bundle selected.');
        return;
      }

      // Replace with actual Selcom API endpoint for payment lookup
      const response = await axios.post('http://example.com/v1/c2b/lookup', {
        operator: 'AIRTELMONEY',
        transid: 'XYZ123444',
        reference: '033XX12211',
        utilityref: '0782710280',
        msisdn: '06534567891',
      });

      // Assuming Selcom returns a payment URL
      const paymentUrl = response.data.paymentUrl;

      // Redirect the user to the payment page
      window.location.href = paymentUrl;
    } catch (error) {
      console.error('Error requesting payment:', error);
      // Handle error, display an error message, or navigate to the home page
    }
  };

  const handleBuyVoucherClick = async () => {
    try {
      // Call the Selcom payment API for the selected bundle to be purchased
      await requestPayment();

      // On Success, Navigate to succeed.js page to display the purchased voucher information to the user
      if (selectedBundle) {
        // Pass the selected bundle to the context
        setSelectedBundleContext(selectedBundle);

        // Only Redirect to Succeed.js with voucherId and voucherNumber from PackageContext when the transaction is successful
        navigate(`/succeed/${selectedBundle.id}`);
      }
    } catch (error) {
      // On Failure, Handle the error and navigate back to the homepage where the user can choose to buy any bundle
      console.error('Payment failed:', error);
      navigate(`/`);
    }
  };

  return (
    <div className="bg-black h-screen w-screen flex flex-col items-center justify-center bg-cover flex-shrink-0 overflow-hidden" style={{ backgroundImage: `url(${BG})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover' }}>
      <img
        src={Logo}
        alt="Kitonga (Logo)"
        className='shrink-0 w-[400px]'
      />
      <div className='bg-zinc-300 bg-opacity-10 flex flex-col items-center pt-5 pb-2 rounded-3xl px-[50px] max-sm:px-[5px] xl:w-[40%] w-[55%] max-md:w-[70%] max-sm:w-[85%]'>
        <div className="text-white text-2xl font-bold text-center whitespace-nowrap">
          Internet Plan
        </div>
        <div className="text-neutral-600 text-center text-base leading-[4x]">
          Vifurushi vya internet
        </div>
        {/* Bundles */}
        {bundles.map((bundle) => (
          <div
            key={bundle.id}
            className={`shadow-sm p-5 bg-emerald-950 bg-opacity-70 self-stretch flex items-center justify-between m-2 rounded-3xl max-md:px-2 ${selectedBundle === bundle ? SelectedPlanActiveStyle : ''}`}
            onClick={() => handleBundleClick(bundle)}
          >
            <div className="px-2 text-white text-base max-sm:text-[12px] my-auto">
              Tzs {bundle.price}
            </div>
            <div className="px-2 text-zinc-50 text-2xl max-sm:text-[15px] text-center font-bold self-center">
              {bundle.duration}
            </div>
            <div className="px-2 self-stretch flex flex-col items-center">
              <div className="text-sky-700 text-base max-sm:text-[12px]">
                Non-Expired
              </div>
              <div className="text-neutral-500 text-sm max-sm:text-[10px]">
                5G Unlimited
              </div>
            </div>
          </div>
        ))}
      </div>
      <div
        className={`text-white text-center bg-blue-900 hover:bg-blue-500 text-base whitespace-nowrap self-center items-center mt-3 px-5 py-5 rounded-xl px-[50px] max-sm:px-[5px] xl:w-[28%] w-[35%] max-md:w-[50%] max-sm:w-[65%] ${selectedBundle ? '' : 'cursor-not-allowed opacity-50'}`}
        onClick={handleBuyVoucherClick}
      >
        Nunua Voucher
      </div>
    </div>
  );
}