import React, { createContext, useContext, useState } from 'react';

const PackageContext = createContext();

export const PackageProvider = ({ children }) => {
  const [selectedBundle, setSelectedBundle] = useState(null);

  const setSelectedBundleContext = (bundle) => {
    setSelectedBundle(bundle);
  };

  const contextValue = {
    selectedBundle,
    setSelectedBundleContext,
    bundles: [
      { id: 1, price: 500, duration: '1 Hour' },
      { id: 2, price: 1000, duration: '4 Hours' },
      { id: 3, price: 2000, duration: '12 Hours' },
    ],
  };

  // Call the selcom payment API for the selected bundle to be purchased
  // const handlePaymentRequest = (req, res) => {
  //   // Logic goes here...
    
  // // On Success, Navigate to succeed.js page to display the purchased voucher information to the user
  // // On Failure, Navigate to back to the homepage where the user can choose to buy any bundle
  // }

  return (
    <PackageContext.Provider value={contextValue}>
      {children}
    </PackageContext.Provider>
  );
};

export const usePackageContext = () => {
  return useContext(PackageContext);
};
