import React from 'react';
import './index.css';
import * as ReactDOM from "react-dom/client";
import reportWebVitals from './reportWebVitals';
import App from './App';

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
reportWebVitals();
