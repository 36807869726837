import React, { useState } from 'react';
import { usePackageContext } from '../hooks/PackageContext';
import { useNavigate, useParams } from 'react-router-dom';
import { BG, Logo } from '../assets';
import '../App.css';
import html2canvas from 'html2canvas';

const SelectedPlanActiveStyle = 'border-2 border-solid border-blue-600';

export default function Succeed() {
  const { selectedBundle } = usePackageContext();
  const navigate = useNavigate();
  const { voucherId } = useParams();
  const [isCopied, setIsCopied] = useState(false);

  const voucherNumber = '8329077345'; // Replace with your actual voucher number

  const handleCopyClick = () => {
    navigator.clipboard.writeText(voucherNumber);
    setIsCopied(true);

    // Reset the copied state after 2 seconds
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const handleSaveClick = () => {
    // Use html2canvas to take a screenshot
    html2canvas(document.body).then((canvas) => {
      const screenshotUrl = canvas.toDataURL('image/png');
  
      // Extract the file extension from the data URL
      const fileExtension = screenshotUrl.split(';')[0].split('/')[1];
  
      // Set the filename using the voucherNumber and file extension
      const filename = `Kitonga - ${voucherNumber}.${fileExtension}`;
  
      // Create a link element and trigger a download
      const link = document.createElement('a');
      link.href = screenshotUrl;
      link.download = filename;
      link.click();
    });
  };
  

  // Handle case where there is no selected bundle
  if (!selectedBundle) {
    // Redirect to an error page or handle the error appropriately
    navigate('/error');
    return null;
  }

  return (
    <div className="bg-black h-screen w-screen flex flex-col items-center justify-center bg-cover flex-shrink-0" style={{ backgroundImage: `url(${BG})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover' }}>
      <img
        src={Logo}
        alt="Kitonga (Logo)"
        className='shrink-0 w-[400px]'
      />
      <div className="bg-zinc-300 bg-opacity-10 flex flex-col items-center pt-5 pb-2 rounded-3xl px-[50px] max-sm:px-[5px] xl:w-[40%] w-[55%] max-md:w-[70%] max-sm:w-[85%]">
        <div className="text-white text-center text-2xl font-bold self-center whitespace-nowrap">
          Transaction Succeed
        </div>
        <div className="text-green-500 text-center text-base leading-[4x]">
          Muamala umefanikiwa
        </div>
        {/* Package */}
        <div className={`shadow-sm p-5 bg-emerald-950 bg-opacity-70 self-stretch flex items-center justify-between m-2 rounded-3xl max-md:px-2 ${SelectedPlanActiveStyle}`}>
          <div className="px-2 text-white text-base max-sm:text-[12px] my-auto">
            Tzs {selectedBundle.price}
          </div>
          <div className="px-2 text-zinc-50 text-2xl max-sm:text-[15px] text-center font-bold self-center">
            {selectedBundle.duration}
          </div>
          <div className="px-2 self-stretch flex flex-col items-center">
            <div className="text-sky-700 text-base max-sm:text-[12px]">
              Non-Expired
            </div>
            <div className="text-neutral-500 text-sm max-sm:text-[10px]">
              5G Unlimited
            </div>
          </div>
        </div>
        <div className="text-zinc-500 text-center text-xl font-light leading-6 max-w-[269px] self-center">
          Download speed: 5.00 Mbps <br />
          Upload speed: 5.00 Mbps
        </div>
        <div className="text-white text-4xl font-semibold self-center pt-2">
          {voucherNumber}
        </div>
        <div className="text-zinc-400 text-center text-base self-center gap-5">
          {isCopied ? (
            <span className="text-blue-500">Voucher copied to clipboard!</span>
          ) : (
            <span className="cursor-pointer" onClick={handleCopyClick}>
              Copy
            </span>
          )}
        </div>
      </div>
      <div className="text-white text-center bg-blue-900 hover:bg-blue-500 text-base whitespace-nowrap self-center items-center mt-3 px-5 py-5 rounded-xl px-[50px] max-sm:px-[5px] xl:w-[28%] w-[35%] max-md:w-[50%] max-sm:w-[65%]" onClick={handleSaveClick}>
        Save Voucher
      </div>
    </div>
  );
}