import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page" className="flex flex-col h-screen w-screen justify-center items-center bg-black">
      <img
          src='./assets/Logo.png'
          alt="Kitonga (Logo)"
          className='shrink-0 w-[400px]'
        />
      <h1 className="text-white text-[30px]">Oops! Rudi tu nyuma</h1>
      <h1 className="text-white text-[20px]">Iyo page unayoitafuta haipo!!!</h1>
      <p className="text-red-800 pt-5">Sorry, an unexpected error has occurred.</p>
      <p>
        <i className="text-[lightblue]">Page {error.statusText || error.message}!!!</i>
      </p>
    </div>
  );
}