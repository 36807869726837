import React, { useEffect, useState } from 'react';
import Logo from './assets/Logo.png';

const Loader = () => {
  const [showCover, setShowCover] = useState(true);

  useEffect(() => {
    // Set a timeout to hide the cover after 5 seconds  
    const timeout = setTimeout(() => {
      setShowCover(false);
    }, 5000);

    // Clear the timeout if the component unmounts
    return () => clearTimeout(timeout);
  }, []); // Run this effect only once on mount

  const handleImageClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // Add any custom click handling logic here if needed
  };

  return (
    <div className={`flex fixed w-screen h-screen items-center justify-center bg-black ${showCover ? 'block' : 'hidden'}`} style={{ zIndex: 9999 }}>
      {/* Add your cover image and logo here */}
      <img
        src={Logo}
        alt="Kitonga Tanzania Logo"
        className="max-w-full max-h-full"
        draggable="false" // Disable dragging
        onClick={handleImageClick} // Prevent click event
        style={{ pointerEvents: 'none', userSelect: 'none' }} // Disable pointer events and image selection
      />
      {/* <div className='text-center font-bold'>Kitonga Shop Coming Soon</div> */}
    </div>
  );
};

export default Loader;