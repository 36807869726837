import Loader from './Loader';
import { PackageProvider } from './hooks/PackageContext';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import ErrorPage from "./error-page";
import Home from './pages/Home';
import Succeed from './pages/Succeed';

export default function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
      errorElement: <ErrorPage />,
      // children: [
      //   {
      //     path: "succeed/:voucherId",
      //     element: <Succeed />,
      //   },
      // ],
    },
    {
      path: "succeed/:voucherId",
      element: <Succeed />,
    },
  
  ]);
  
  return (
    <PackageProvider>
      <Loader />
      <RouterProvider router={router} />
    </PackageProvider>
  );
}